import React from 'react';

import { LOADING_OPTIONS, Text } from '@components';
import { COMPATIBLE_DEVICES } from '@shared/constants/pages/product-page-common';
import { APPS_LINKS } from '@shared/constants/apps';
import { META_IMAGE, PRICING_LINK } from '@shared/constants/constants';
import { SERVER_COUNT } from '@shared/constants/server-locations';
import { formatLink } from '@shared/format-helpers';
import { trackCustomGA4Event } from '@shared/google-utils';
import { APP_PARAMS, GA4_EVENTS } from '@shared/constants/google';
import { getFetchedImage } from '@shared/image-helpers';
// Footer CTA images
import footerImage from '@images/product-page/common-footer-image.png';
import footerImageTablet from '@images/product-page/common-footer-image-tablet.png';
import footerImageTabletTop from '@images/product-page/common-footer-image-tablet-top.png';
import footerImageMobile from '@images/product-page/common-footer-image-mobile.png';
import footerImageMobileTop from '@images/product-page/common-footer-image-mobile-top.png';
// Steps images
import DownloadInstall from '@images/product-page/download-install.svg';
import ConnectEnjoy from '@images/product-page/connect-enjoy.svg';
import UpgradeAnytime from '@images/product-page/upgrade-anytime.svg';
import GooglePlay from '@images/google-play-badge.svg';
// Perks images
import UnlimitedAccess from '@images/product-page/unlimited-access.svg';
import HassleFree from '@images/product-page/hassle-free.svg';
import PublicProtection from '@images/product-page/public-protection.svg';
import UnblockWebsite from '@images/product-page/unblock-content.svg';

export const PAGE_DATA = ({ images }) => ({
  metaData: (t) => ({
    title: t('vpnForAndroid:metaData.title'),
    description: t('vpnForAndroid:metaData.description'),
    image: META_IMAGE,
    keywords: [
      'VPN for Android',
      'free VPN for Android',
      'free VPN APK for Android',
      'VPN on Android phone',
      'Atlas VPN for Android app',
      'Android VPN app',
      'best free Android VPN',
      'VPN on Android TV',
    ],
  }),
  headerCta: {
    text: ({ isLoading, discountPercentage, pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('vpnForAndroid:headerCta.title'),
      subtitle: t('vpnForAndroid:headerCta.subtitle'),
      description: [
        { title: t('vpnForAndroid:headerCta.productFeatures.unlimitedBandwidth') },
        { title: t('vpnForAndroid:headerCta.productFeatures.protection') },
        { title: t('vpnForAndroid:headerCta.productFeatures.unlockContent') },
        { title: t('vpnForAndroid:headerCta.productFeatures.ultraFast') },
      ],
      buttons: [
        {
          title: t('common:actions.downloadFree'),
          link: APPS_LINKS.android,
          buttonProps: {
            variant: 'outlined',
            customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.android },
          },
        },
      ],
    }),
    image: () => ({
      mobile: {
        src: getFetchedImage(images, 'product-page/android-header-image-mobile.png'),
        alt: 'android page header',
        loading: LOADING_OPTIONS.eager,
      },
      tablet: {
        src: getFetchedImage(images, 'product-page/android-header-image-tablet.png'),
        alt: 'android page header',
        loading: LOADING_OPTIONS.eager,
      },
      desktop: {
        src: getFetchedImage(images, 'product-page/android-header-image.png'),
        alt: 'android page header',
        loading: LOADING_OPTIONS.eager,
        sizes: '2842px',
        position: {
          top: -80,
          height: 723,
          maxWidth: 2842,
        },
      },
    }),
  },
  compatibleDevices: (t) => ({ cards: COMPATIBLE_DEVICES(t), mobileCards: COMPATIBLE_DEVICES(t) }),
  steps: ({ pricingLink = PRICING_LINK, t } = {}) => ({
    title: t('vpnForAndroid:stepCards.title'),
    description: t('vpnForAndroid:stepCards.description'),
    items: [
      {
        image: DownloadInstall,
        step: 1,
        title: t('vpnForAndroid:stepCards.downloadInstall.title'),
        description: t('vpnForAndroid:stepCards.downloadInstall.description'),
        button: {
          image: { src: GooglePlay, alt: 'Get it on Google Play' },
          handleOnClick: () => {
            window.open(APPS_LINKS.android, '_blank').focus();
            trackCustomGA4Event({ eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.android });
          },
        },
      },
      {
        image: ConnectEnjoy,
        step: 2,
        title: t('vpnForAndroid:stepCards.connectEnjoy.title'),
        description: t('vpnForAndroid:stepCards.connectEnjoy.description'),
      },
      {
        image: UpgradeAnytime,
        step: 3,
        title: t('vpnForAndroid:stepCards.upgradeAnytime.title'),
        description: t('vpnForAndroid:stepCards.upgradeAnytime.description', {
          serverCount: SERVER_COUNT,
        }),
      },
    ],
  }),
  digitalLife: (t) => ({
    title: t('vpnForAndroid:reclaimControl'),
    description: t('vpnForAndroid:joinUs'),
    data: [
      {
        isReversed: true,
        image: getFetchedImage(images, 'product-page/high-speed.png'),
        title: t('vpnForAndroid:contentBlocks.speed.title'),
        description: t('vpnForAndroid:contentBlocks.speed.description'),
        link: {
          title: t('common:links.waysToUseVpn'),
          link: formatLink(t('navigation:paths.use-cases')),
          customEvent: { eventName: GA4_EVENTS.waysToUseVpnClick },
        },
      },
    ],
  }),
  perksBefore: (t) => ({
    items: [
      {
        image: UnlimitedAccess,
        title: t('vpnForAndroid:perkCards.unlimitedAccess.title'),
        description: t('vpnForAndroid:perkCards.unlimitedAccess.description'),
      },
      {
        image: HassleFree,
        title: t('vpnForAndroid:perkCards.hassleFree.title'),
        description: t('vpnForAndroid:perkCards.hassleFree.description'),
      },
    ],
  }),
  onlineSafety: (t) => ({
    data: [
      {
        image: getFetchedImage(images, 'product-page/safety-for-activities.png'),
        title: t('vpnForAndroid:contentBlocks.safety.title'),
        description: (
          <React.Fragment>
            <span>{t('vpnForAndroid:contentBlocks.safety.description')}</span>
            <Text.Body3 as="span" display="block" mt={16}>
              {t('common:wireGuardTrademark')}
            </Text.Body3>
          </React.Fragment>
        ),
      },
    ],
  }),
  perksAfter: ({ pricingLink = PRICING_LINK, t } = {}) => ({
    link: {
      title: t('common:links.seeListFeatures'),
      link: formatLink(t('navigation:paths.features')),
      customEvent: { eventName: GA4_EVENTS.fullFeaturesClick },
    },
    items: [
      {
        image: PublicProtection,
        title: t('vpnForAndroid:perkCards.publicProtection.title'),
        description: t('vpnForAndroid:perkCards.publicProtection.description'),
      },
      {
        image: UnblockWebsite,
        title: t('vpnForAndroid:perkCards.unblockWebsites.title'),
        description: t('vpnForAndroid:perkCards.unblockWebsites.description'),
      },
    ],
  }),
  reviews: (t) => ({
    title: t('vpnForAndroid:reviews.title'),
  }),
  moneyBackGuarantee: (t) => ({
    data: [
      {
        image: getFetchedImage(images, 'product-page/30-day-guarantee.png'),
        title: t('common:moneyBackGuarantee14.title'),
        description: t('common:moneyBackGuarantee14.description'),
      },
    ],
  }),
  pricing: ({ t }) => ({
    title: t('vpnForAndroid:pricingSection.title'),
    description: t('vpnForAndroid:pricingSection.description'),
    footerProps: {
      text: t('vpnForAndroid:pricingSection.notReadyYet'),
      button: {
        link: APPS_LINKS.android,
        title: t('common:actions.download'),
        buttonProps: {
          customEvent: { eventName: GA4_EVENTS.appDownload, params: APP_PARAMS.android },
        },
      },
    },
  }),
  faq: ({ locationCount }) => ({
    title: 'Frequently asked questions',
    items: [
      {
        header: 'Can you use Atlas VPN for free?',
        content:
          'Yes, you can use the free version of the Atlas VPN for Android app. It features multiple free servers that you can connect to without paying a cent. You can upgrade to premium at any point to increase the number of available servers, enjoy additional features, and boost performance.',
      },
      {
        header: 'Why should you upgrade to premium?',
        listLabel: 'Premium services bring:',
        listItems: [
          'Video-optimized servers',
          `More than ${SERVER_COUNT} servers in ${locationCount} locations worldwide`,
          '24/7 customer support',
          'Simultaneous premium services usage on an unlimited number of devices',
          'Privacy Pro servers for more security',
          'Access to Android TV and Amazon Fire TV apps',
          'No data limit and better speed',
        ],
      },
      {
        header: 'Is it necessary to use a VPN on Android?',
        content:
          'Yes, you should use a VPN on Android. Users typically use their mobile devices for important tasks, such as checking their bank accounts, shopping, or communicating with friends. All these activities need protection. A VPN helps secure all these actions and prevents entities from invading your privacy. Thus, you do not have to worry about your information being leaked, stolen, or tracked.',
      },
      {
        header: 'Does a VPN protect you on unsecured Wi-Fi networks?',
        content:
          'Yes, definitely! Many free public Wi-Fi options do not offer the best security setup. Instead, they might leave your data unencrypted and make it easy for other users to spy on your actions. A VPN resolves these concerns by encrypting all of your traffic, meaning that all your data travels safely online.',
      },
      {
        header: 'Can you use Atlas VPN on multiple devices?',
        content:
          'There is no limit to the number of devices you can choose to protect with Atlas VPN. You can use it on your Android, iOS, macOS, Windows, Android TV, and Amazon Fire TV devices simultaneously. It is a great family-style deal, too! Help your loved ones stay safe online and protect your family’s private information, financial transactions, and digital identities. Sharing is caring, and Atlas VPN strongly believes in this principle.',
      },
    ],
  }),
  footerCta: {
    text: ({ pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('vpnForAndroid:footerCta.title'),
      description: t('vpnForAndroid:footerCta.description'),
    }),
    image: () => ({
      mobile: {
        src: footerImageMobile,
        alt: 'Android footer',
      },
      mobileTop: {
        src: footerImageMobileTop,
        alt: 'Android footer',
      },
      tablet: {
        src: footerImageTablet,
        alt: 'Android footer',
      },
      tabletTop: {
        src: footerImageTabletTop,
        alt: 'Android footer',
      },
      desktop: {
        src: footerImage,
        alt: 'Android footer',
        position: {
          bottom: 0,
          height: 540,
          maxWidth: 3000,
        },
      },
    }),
  },
});
